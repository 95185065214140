import { Stack } from "@mui/system";
import Navbar, { NavbarButton } from "./Navbar";
import NoConnection from "./NoConnection";
import { useSearchParams } from "react-router-dom";
import { GRID_SPACING } from "./AstroContentColumns";
import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { PALETTE } from "../palette";
import Typography from "./Typography";
import UrsorButton from "./UrsorButton";
import SchoolJoiningDialog from "../pages/auth/SchoolJoiningDialog";
import UrsorParticles from "./UrsorParticles";
import { ContentAgeMode } from "../pages/main/SearchPage";

export interface IPageLayoutProps {
  children: React.ReactNode;
  selected: NavbarButton;
  backgroundColor?: string;
  overlayParticles?: boolean;
}

const JoinSchoolBanner = (props: { callback: () => void }) => (
  <Stack
    px={`calc(2*${GRID_SPACING})`}
    height="58px"
    minHeight="58px"
    width="100%"
    top="92px"
    boxSizing="border-box"
  >
    <Stack
      flex={1}
      bgcolor={PALETTE.system.orange}
      borderRadius="12px"
      justifyContent="center"
      alignItems="center"
      direction="row"
      spacing="20px"
    >
      <Typography variant="medium" bold color={PALETTE.font.light}>
        Connect to a School
      </Typography>
      <UrsorButton
        size="small"
        dark
        fontColor={PALETTE.system.orange}
        onClick={props.callback}
        //onClick={() => ApiController.migrate()}
      >
        Connect
      </UrsorButton>
    </Stack>
  </Stack>
);

export default function PageLayout(props: IPageLayoutProps) {
  const [searchParams] = useSearchParams();
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );
  const [contentAgeMode, setContentAgeMode] = useLocalStorage<ContentAgeMode>(
    "contentAgeMode",
    "explorer"
  );

  const [joinSchoolDialogOpen, setJoinSchoolDialogOpen] =
    useState<boolean>(false);
  return (
    <>
      <UrsorParticles zIndex={props.overlayParticles ? 2 : 0} />
      <Stack
        flex={1}
        //bgcolor={props.backgroundColor || PALETTE.secondary.grey[1]}
        overflow="hidden"
        zIndex={1}
      >
        <NoConnection>
          {searchParams.get("isapp") !== "true" ? (
            <Stack>
              <Navbar
                selected={props.selected}
                noSearch={contentAgeMode === "trailblazer"}
              />
              {!deviceId ? (
                <Stack pt={GRID_SPACING}>
                  <JoinSchoolBanner
                    callback={() => setJoinSchoolDialogOpen(true)}
                  />
                </Stack>
              ) : null}
            </Stack>
          ) : null}
          <Stack flex={1} overflow="hidden">
            {props.children}
          </Stack>
        </NoConnection>
      </Stack>
      {joinSchoolDialogOpen ? (
        <SchoolJoiningDialog
          closeCallback={() => setJoinSchoolDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
