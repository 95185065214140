import React, { useState } from "react";
import { Box, Stack } from "@mui/system";
import Typography from "../../../components/Typography";
import { PALETTE } from "../../../palette";
import { ReactComponent as LinkIcon } from "../../../images/icons/LinkIcon.svg";
import { getMaxLinesStyle } from "../../../components/DetailsSectionItem";
import { getAbsoluteUrl, getPrefixRemovedUrl } from "./LinkCard";

const PADDING = "10px";
export const MIN_WIDTH = "175px";
const HEIGHT = "243px";
const IMAGE_HEIGHT = "144px";
const BORDER_RADIUS = "12px";
export const PLACEHOLDER_IMAGE_URL_COMMON_SECTION =
  "https://ursorassets.s3.eu-west-1.amazonaws.com/img/cardAssets/patterns/pattern";

export interface IPlatform {
  id: string;
  title: string;
  url: string;
  accessibleUrl: string;
  img: string;
  yearGroups: number[];
  schoolId: string;
  creatorId: string;
  installed: boolean;
  createdAt: string;
}

export interface IPlatformCardProps {
  platform: IPlatform;
}

const PlatformCard = (props: IPlatformCardProps) => {
  const [hovering, setHovering] = useState<boolean>(false);
  return (
    <Stack
      position="relative"
      sx={{
        cursor: "pointer",
        opacity: hovering ? 0.7 : 1,
        transition: "0.2s",
      }}
    >
      <Box
        position="absolute"
        width="100%"
        height="185px"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
      <Stack
        borderRadius={BORDER_RADIUS}
        p={PADDING}
        sx={{ boxSizing: "border-box" }}
        spacing="12px"
        bgcolor="#ffffff"
      >
        <Stack
          sx={{
            backgroundImage: `url(${props.platform.img})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundColor: props.platform.img.includes(
              PLACEHOLDER_IMAGE_URL_COMMON_SECTION
            )
              ? PALETTE.secondary.grey[2]
              : undefined,
          }}
          height={IMAGE_HEIGHT}
          width="100%"
          borderRadius="8px"
        />
        <Stack spacing="4px">
          <Box width="84%">
            <Typography variant="small" bold sx={getMaxLinesStyle(1)}>
              {props.platform.title}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PlatformCard;
