import { useEffect, useState } from "react";
import ByteController, { ByteAnimation } from "./ByteController";
import Typography from "./Typography";
import { Box, Stack, keyframes } from "@mui/system";
import { PALETTE } from "../palette";

const PULSE_AMPLITUDE = "6px";
const PULSE_PERIOD = "2.9s";

export const pulse = keyframes`
  from {
    transform: translateY(-${PULSE_AMPLITUDE})
  }
  to {
    transform: translateY(${PULSE_AMPLITUDE})
  }
`;

const BYTE_INTRO_MESSAGE = "Ask me anything!";
const BYTE_SEQUENCE: { animation: ByteAnimation; text: string }[] = [
  { animation: "celebration", text: "HeeHee!" },
  { animation: "click", text: "Beep Boop" },
  { animation: "click", text: "Hey!" },
  { animation: "click", text: "Stop tickling me!" },
  { animation: "loading", text: "101010" },
  { animation: "oops", text: "Ok, enough..." },
];

const BuddyByte = (props: { animation?: ByteAnimation }) => {
  const [byteClickCount, setByteClickCount] = useState<number | undefined>(
    undefined
  );
  const [showNoResults, setShowNoResults] = useState<boolean>(false);
  const [animation, setAnimation] = useState<ByteAnimation | undefined>(
    "appear"
  );
  useEffect(() => setAnimation(props.animation), [props.animation]);
  const [speechBubbleContent, setSpeechBubbleContent] =
    useState<string>(BYTE_INTRO_MESSAGE);
  const [speechBubbleVisible, setSpeechBubbleVisible] = useState<boolean>(true);

  useEffect(() => {
    if (byteClickCount) {
      setSpeechBubbleVisible(true);
      const timer = setTimeout(() => setSpeechBubbleVisible(false), 2500);
      return () => clearTimeout(timer);
    }
  }, [byteClickCount]);

  useEffect(() => {
    if (byteClickCount) {
      const currentByte =
        BYTE_SEQUENCE[(byteClickCount - 1) % BYTE_SEQUENCE.length];
      setAnimation(currentByte.animation);
      setSpeechBubbleContent(currentByte.text);
    }
  }, [byteClickCount]);

  return (
    <Stack
      width="60px"
      height="60px"
      sx={{
        animation: `${pulse} ${PULSE_PERIOD} ease-in-out`,
        animationDirection: "alternate",
        animationIterationCount: "infinite",
        cursor: "pointer",
      }}
      onClick={() => setByteClickCount((byteClickCount ?? 0) + 1)}
      position="relative"
      direction="row"
    >
      <Stack
        flex={1}
        sx={{
          "&:hover": { transform: "scale(1.1) rotate(6deg)" },
          transition: "0.3s",
          transform: "translateY(-2px)",
        }}
        height="60px"
      >
        <ByteController
          key={byteClickCount}
          animation={animation}
          animationEndCallback={() => setAnimation(undefined)}
          size={100}
        />
      </Stack>
    </Stack>
  );
};

export default BuddyByte;
