import React from "react";
import { useLottie } from "lottie-react";
import byteIdle from "../lotties/byteIdle.json";
import byteAppear from "../lotties/byteAppear.json";
import byteDisappear from "../lotties/byteDisappear.json";
import byteCelebration from "../lotties/byteCelebration.json";
import byteOops from "../lotties/byteOops.json";
import byteLoading from "../lotties/byteLoading.json";
import byteClick from "../lotties/byteClick.json";
import { Box } from "@mui/system";

export type ByteAnimation =
  | "idle"
  | "appear"
  | "disappear"
  | "celebration"
  | "oops"
  | "loading"
  | "click";
const JSONS: Record<ByteAnimation, any> = {
  idle: byteIdle,
  appear: byteAppear,
  disappear: byteDisappear,
  celebration: byteCelebration,
  oops: byteOops,
  loading: byteLoading,
  click: byteClick,
};

export interface IByteAnimationProps {
  callback?: () => void;
  lottieJson: any;
}

function Byte(props: IByteAnimationProps) {
  const options = {
    animationData: props.lottieJson,
    autoplay: true,
    loop: false,
    onComplete: props.callback,
  };
  const { View } = useLottie(options, {});
  return View;
}

export interface IByteControllerProps {
  animation?: ByteAnimation;
  animationEndCallback?: () => void;
  size: number;
  delay?: number;
}

export default function ByteController(props: IByteControllerProps) {
  return (
    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
      <Byte
        lottieJson={JSONS[props.animation ?? "idle"]}
        callback={props.animationEndCallback}
      />
    </Box>
  );
}
