import React from "react";
import { Box, Stack } from "@mui/system";
import Typography from "./Typography";

export const getMaxLinesStyle = (n: number) => ({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: n,
  wordBreak: n === 1 ? "break-all" : "break-word",
});

const MAX_N_LINES = 9;
const ICON_SIZE = "23px";

export interface IDetailsSectionItemProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  content: string;
}

export default function DetailsSectionItem(props: IDetailsSectionItemProps) {
  return (
    <Stack direction="row" spacing="10px">
      <Box pt="2px">
        <props.icon height={ICON_SIZE} width={ICON_SIZE} />
      </Box>
      <Stack>
        <Typography variant="large" bold>
          {props.title}
        </Typography>
        <Typography
          sx={{
            transform: "translateX(1px)",
            ...getMaxLinesStyle(MAX_N_LINES),
          }}
          variant="normal"
        >
          {props.content}
        </Typography>
      </Stack>
    </Stack>
  );
}
