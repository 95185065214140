import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ApiController from "../../apiController";
import AstroContentColumns from "../../components/AstroContentColumns";
import { PALETTE } from "../../palette";
import Navbar from "../../components/Navbar";
import { IPlatform } from "./components/PlatformCard";
import { useLocalStorage } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";

const PADDING = "20px";
export const OVERALL_TOP_PADDING = "78px";

const MIN_COLUMN_WIDTH = 120;
const MAX_COLUMN_WIDTH = 220;
const IDEAL_COLUMN_WIDTH = 176;

export default function PlatformsPage(props: { showJoiningBanner: boolean }) {
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );
  const [searchParams] = useSearchParams();

  const [platforms, setPlatforms] = useState<IPlatform[]>([]);
  useEffect(() => {
    (deviceId
      ? ApiController.getPlatforms(deviceId)
      : ApiController.getGuestPlatforms()
    ).then((platforms) => setPlatforms(platforms));
  }, [deviceId]);

  return (
    <PageLayout selected="apps">
      <Stack
        width="100vw"
        height="100vh"
        overflow="hidden"
        bgcolor={PALETTE.secondary.grey[1]}
      >
        <Stack flex={1} direction="row" overflow="hidden" px="14px">
          <AstroContentColumns
            title="Apps"
            links={[]}
            stacks={[]}
            platforms={platforms}
            searchResults={[]}
            minColumnWidth={MIN_COLUMN_WIDTH}
            maxColumnWidth={MAX_COLUMN_WIDTH}
            idealColumnWidth={IDEAL_COLUMN_WIDTH}
            emptyStateText="No Platforms yet."
          />
        </Stack>
      </Stack>
    </PageLayout>
  );
}
