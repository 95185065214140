import React, { useEffect, useRef, useState } from "react";
import { Box, keyframes, Stack } from "@mui/system";
import ByteController, { ByteAnimation } from "../../components/ByteController";
import { PALETTE } from "../../palette";
import { Input } from "@mui/material";
import Typography, {
  FONT_SIZES,
  LINE_HEIGHTS,
} from "../../components/Typography";
import background from "../../images/backgrounds/signin.png";
import { ReactComponent as Clutter } from "../../images/colorfulClutter.svg";
import { ReactComponent as SearchIcon } from "../../images/icons/SearchIcon.svg";
import ApiController from "../../apiController";
import UrsorFadeIn from "../../components/UrsorFadeIn";
import { ISearchResult } from "./components/SearchResultCard";
import { useLocation, useNavigate } from "react-router-dom";
import { ITag } from "../../components/TagGrid";
import { useLocalStorage } from "usehooks-ts";
import { useSearchParams } from "react-router-dom";

export const HIDE_SCROLLBAR = { "::-webkit-scrollbar": { display: "none" } };

export interface IPlatform {
  title: string;
  url: string;
  accessibleUrl: string;
  img: string;
  yearGroups: string[];
  schoolId: string;
  creatorId: string; // teacher id
  installed: boolean;
}

const SEARCH_BAR_WIDTH = "620px";
const SEARCH_BAR_HEIGHT = 60;
const SEARCH_BAR_BUTTON_SIZE = 48;

const SPEECH_BUBBLE_PADDING = "12px";

const PULSE_AMPLITUDE = "14px";
const PULSE_PERIOD = "2.6s";

const BYTE_INTRO_MESSAGE = "Ask me anything!";
const BYTE_NO_RESULTS_MESSAGE = "No results! Try something else.";

const PLATFORMS_GRID_SPACING = "28px";
const GRID_SPACING = "35px";
const GRID_X_PADDING = "100px";

const LINK_CARDS_PER_SEARCH_RESULT_CARD = 3;
const TWO_CARD_THRESHOLD_WINDOW_WIDTH = 955;

const SEARCH_HISTORY_MAX_SIZE = 7;

const PAGE_SIZE = 6;

type ResultCard = "standard" | "video" | "search";

const BYTE_SEQUENCE: { animation: ByteAnimation; text: string }[] = [
  { animation: "celebration", text: "HeeHee!" },
  { animation: "click", text: "Beep Boop" },
  { animation: "click", text: "Hey!" },
  { animation: "click", text: "Stop tickling me!" },
  { animation: "loading", text: "101010" },
  { animation: "oops", text: "Ok, enough..." },
];

export const pulse = keyframes`
  from {
    transform: translateY(-${PULSE_AMPLITUDE})
  }
  to {
    transform: translateY(${PULSE_AMPLITUDE})
  }
`;

function SearchBar(props: {
  value: string;
  changeCallback: (newValue: string) => void;
  submitCallback: () => void;
}) {
  return (
    <Stack
      width={SEARCH_BAR_WIDTH}
      height={SEARCH_BAR_HEIGHT}
      borderRadius={SEARCH_BAR_HEIGHT}
      bgcolor="white"
      border={`3px solid ${
        props.value ? PALETTE.secondary.purple[2] : PALETTE.secondary.grey[2]
      }`}
      alignItems="center"
      justifyContent="center"
      position="relative"
      overflow="visible"
      sx={{ transition: "0.2s" }}
      direction="row"
      spacing="10px"
    >
      <Box position="absolute" sx={{ pointerEvents: "none" }}>
        <Clutter width="700px" height="auto" />
      </Box>
      <Stack width="100%" direction="row" alignItems="space-between">
        <Input
          autoFocus
          fullWidth
          placeholder="Search an idea"
          inputProps={{
            style: {
              fontSize: FONT_SIZES.h5,
              textAlign: "left",
              color: PALETTE.secondary.purple[2],
              paddingLeft: "12px",
            },
            form: {
              autoComplete: "off",
            },
          }}
          value={props.value}
          disableUnderline={true}
          sx={{
            fontFamily: "Rubik",
            fontWeight: 500,
            fontSize: FONT_SIZES.h5,
            lineHeight: LINE_HEIGHTS.h5,
          }}
          onChange={(event) => props.changeCallback(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && props.value) {
              props.submitCallback();
            }
          }}
        />
        <Stack
          px={`${(SEARCH_BAR_HEIGHT - SEARCH_BAR_BUTTON_SIZE) / 2}px`}
          height="100%"
          justifyContent="center"
          alignItems="center"
          onClick={props.submitCallback}
        >
          <Stack
            height={SEARCH_BAR_BUTTON_SIZE}
            width={SEARCH_BAR_BUTTON_SIZE}
            borderRadius="100%"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: props.value
                ? PALETTE.gradients.vibrant
                : PALETTE.secondary.grey[1],
              svg: {
                path: {
                  fill: props.value ? "white" : PALETTE.secondary.grey[2],
                },
              },
              "&:hover": { opacity: 0.7 },
              transition: "0.2s",
              cursor: "pointer",
            }}
          >
            <SearchIcon width={25} height={25} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

interface IUrsorDropdownRowProps {
  value: string;
  secondaryValue?: string;
  icon?: JSX.Element;
  hovering?: boolean;
  ///callback?: () => void;
}

const DROPDOWN_LIST_ROW_HEIGHT = "32px";
const DROPDOWN_LIST_MAX_HEIGHT = "400px";

export function UrsorDropdownListRow(props: IUrsorDropdownRowProps) {
  return (
    <Stack
      width="100%"
      direction="row"
      spacing="7px"
      height={DROPDOWN_LIST_ROW_HEIGHT}
      px="16px"
      boxSizing="border-box"
      justifyContent="center"
      // onClick={() => props.callback?.()}
      alignItems="center"
      sx={{
        cursor: "pointer",
      }}
      bgcolor={props.hovering ? PALETTE.secondary.grey[1] : "rgba(255,255,255)"}
    >
      {props.icon ? props.icon : null}
      <Stack width="100%" minWidth={0}>
        <Typography
          noWrap
          variant="small"
          sx={{ lineHeight: "100%" }}
          color={
            props.hovering ? PALETTE.secondary.purple[2] : PALETTE.font.dark
          }
        >
          {props.value}
        </Typography>
        {props.secondaryValue ? (
          <Typography noWrap variant="tiny" color={PALETTE.secondary.grey[3]}>
            {props.secondaryValue}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
}

export function UrsorDropdownList(props: {
  rows: (Pick<IUrsorDropdownRowProps, "value" | "secondaryValue" | "icon"> & {
    id: string;
    callback: () => void;
  })[];
  title?: string;
  titleIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  width?: string;
}) {
  const [hoverRowId, setHoverRowId] = useState<string | undefined>(undefined);
  const Icon = props.titleIcon;
  return (
    <Stack
      width={props.width || "100%"}
      maxHeight={DROPDOWN_LIST_MAX_HEIGHT}
      bgcolor="rgb(255,255,255)"
      borderRadius="12px"
      boxShadow="0 0 25px rgba(0,0,0,0.05)"
    >
      {props.title ? (
        <Stack
          justifyContent="flex-end"
          sx={{
            svg: {
              path: {
                fill: PALETTE.secondary.grey[3],
              },
            },
          }}
          height="30px"
          pl="16px"
          pb="5px"
        >
          <Stack alignItems="center" direction="row" spacing="4px">
            {Icon ? <Icon height="12px" width="12px" /> : null}
            <Typography variant="small" color={PALETTE.secondary.grey[3]}>
              {props.title}
            </Typography>
          </Stack>
        </Stack>
      ) : null}
      <Stack borderRadius="0 0 12px 12px" overflow="hidden">
        {props.rows.map((row) => (
          <Stack
            key={row.id}
            onClick={row.callback}
            onMouseEnter={() => setHoverRowId(row.id)}
            onMouseLeave={() =>
              setHoverRowId((prev) => (prev === row.id ? undefined : prev))
            }
          >
            <UrsorDropdownListRow {...row} hovering={row.id === hoverRowId} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export interface ISearchPageLandingViewProps {
  blockedTerm: boolean;
}

export default function SearchPageLandingView(
  props: ISearchPageLandingViewProps
) {
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );

  const [searchParams] = useSearchParams();

  const [inputedValue, setInputedValue] = useState<string>("");
  const [committedInputedValue, setCommittedInputedValue] =
    useState<string>("");

  const [byteClickCount, setByteClickCount] = useState<number | undefined>(
    undefined
  );
  const [showNoResults, setShowNoResults] = useState<boolean>(false);
  const [animation, setAnimation] = useState<ByteAnimation | undefined>(
    "appear"
  );
  const [speechBubbleContent, setSpeechBubbleContent] =
    useState<string>(BYTE_INTRO_MESSAGE);
  const [speechBubbleVisible, setSpeechBubbleVisible] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [schoolId, setSchoolId] = useLocalStorage<string | undefined>(
    "schoolId",
    undefined
  );

  useEffect(() => {
    !committedInputedValue && setSearchResults([]);
  }, [committedInputedValue]);

  useEffect(() => {
    loading && setAnimation("loading");
    showNoResults && setAnimation("oops");
  }, [loading, showNoResults]);

  useEffect(() => {
    if (byteClickCount) {
      setSpeechBubbleVisible(true);
      const timer = setTimeout(() => setSpeechBubbleVisible(false), 2500);
      return () => clearTimeout(timer);
    }
  }, [byteClickCount]);

  useEffect(() => {
    if (showNoResults) {
      setSpeechBubbleVisible(true);
    } else {
      loading && setSpeechBubbleVisible(false);
    }
  }, [loading, showNoResults]);

  useEffect(() => {
    if (inputedValue) {
      setShowNoResults(false);
      setSpeechBubbleVisible(false);
    }
  }, [inputedValue]);

  useEffect(() => {
    if (byteClickCount) {
      const currentByte =
        BYTE_SEQUENCE[(byteClickCount - 1) % BYTE_SEQUENCE.length];
      setAnimation(currentByte.animation);
      setSpeechBubbleContent(currentByte.text);
    }
  }, [byteClickCount]);

  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    "searchHistory",
    []
  );

  const navigate = useNavigate();

  useEffect(() => {
    props.blockedTerm && setAnimation("oops");
    props.blockedTerm &&
      setSpeechBubbleContent("Try searching for something less naughty.");
  }, [props.blockedTerm]);

  return (
    <Stack
      flex={1}
      spacing="40px"
      alignItems="center"
      justifyContent="center"
      overflow="scroll"
      sx={{
        ...HIDE_SCROLLBAR,
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Stack alignItems="center" spacing="20px" pt="80px">
        <Box
          width="200px"
          height="200px"
          sx={{
            animation: `${pulse} ${PULSE_PERIOD} ease-in-out`,
            animationDirection: "alternate",
            animationIterationCount: "infinite",
            cursor: "pointer",
          }}
          onClick={() => setByteClickCount((byteClickCount ?? 0) + 1)}
          zIndex={10000}
          position="relative"
        >
          <Stack
            direction="row"
            position="absolute"
            alignItems="flex-end"
            left="200px"
            top="-13px"
            sx={{
              opacity: speechBubbleVisible ? 1 : 0,
              transition: "0.3s",
            }}
          >
            <Box
              height="10px"
              width="6px"
              sx={{
                bgcolor: "rgba(255,255,255,0.7)",
                clipPath: "polygon(0 100%, 100% 0, 100% 100%)",
              }}
            />
            <Stack
              p={SPEECH_BUBBLE_PADDING}
              bgcolor="rgba(255,255,255,0.7)"
              maxWidth="600px"
              width={speechBubbleContent.length > 20 ? "280px" : undefined}
            >
              <Typography
                maxLines={2}
                variant="large"
                sx={{ fontFamily: "IBM Plex Mono" }}
              >
                {speechBubbleContent}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              "&:hover": { transform: "rotate(6deg) scale(1.1)" },
              transition: "0.3s",
              transform: "translateX(-19px)",
              transformOrigin: "right",
            }}
          >
            <ByteController
              key={byteClickCount}
              animation={animation}
              animationEndCallback={() => setAnimation(undefined)}
              size={200}
            />
          </Box>
        </Box>

        {props.blockedTerm ? (
          <Stack spacing="18px" alignItems="center" pb="14px">
            <Typography variant="h1" color="rgba(255,255,255,0.6)">
              Ooooops...
            </Typography>
            <Typography variant="h5" bold color="rgba(255,255,255,0.6)">
              This search term is blocked.
            </Typography>
          </Stack>
        ) : null}

        <UrsorFadeIn duration={1000}>
          <Stack direction="row" alignItems="center" spacing="10px">
            <SearchBar
              value={inputedValue}
              changeCallback={(newValue: string) => setInputedValue(newValue)}
              submitCallback={() => {
                if (!inputedValue) return;
                window.location.href = `/search?q=${inputedValue}${
                  searchParams.get("isapp") === "true" ? "&isapp=true" : ""
                }`;
                searchHistory[searchHistory.length - 1] !== inputedValue &&
                  setSearchHistory(
                    [...(searchHistory ?? []), inputedValue].slice(
                      searchHistory?.length === SEARCH_HISTORY_MAX_SIZE ? 1 : 0
                    )
                  );
              }}
            />
          </Stack>
        </UrsorFadeIn>
      </Stack>
    </Stack>
  );
}
