import axios from "axios";
import axiosRetry from "axios-retry";
import { Auth } from "aws-amplify";

const ASTROSAFE_BACKEND_URLS = {
  dev: "http://localhost:8080",
  staging:
    "https://058vkvcapb.execute-api.eu-west-1.amazonaws.com/dev/dev-ursor-express-serverless",
  prod: "https://xdt8565hsf.execute-api.eu-west-1.amazonaws.com/prod/api",
};

const astroSafeApi = axios.create({
  baseURL: ASTROSAFE_BACKEND_URLS[process.env.REACT_APP_BUILD_ENV],
});

axiosRetry(astroSafeApi, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
});

astroSafeApi.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    config.headers.Email = window.localStorage
      .getItem("email")
      ?.replaceAll('"', "");
    config.headers.Authorization = `Bearer ${window.localStorage
      .getItem("accessToken")
      ?.replaceAll('"', "")}`; // get rid of the enclosing quotation marks
    resolve(config);
  });
});

astroSafeApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    // if not  network error or
    // Request aborted
    //Sentry.captureException(error);
    throw error;
  }
);

const PEDIA_BACKEND_URLS = {
  dev: "http://localhost:8081",
  staging:
    "https://tse16z5923.execute-api.eu-west-1.amazonaws.com/dev/safeplay-backend",
  prod:
    "https://tse16z5923.execute-api.eu-west-1.amazonaws.com/prod/safeplay-backend", //"https://xdt8565hsf.execute-api.eu-west-1.amazonaws.com/prod/api",
};

const pediaApi = axios.create({
  baseURL: PEDIA_BACKEND_URLS[process.env.REACT_APP_BUILD_ENV],
});


axiosRetry(pediaApi, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
});

pediaApi.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    config.headers.Email = window.localStorage
      .getItem("email")
      ?.replaceAll('"', "");
    config.headers.Authorization = `Bearer ${window.localStorage
      .getItem("accessToken")
      ?.replaceAll('"', "")}`; // get rid of the enclosing quotation marks
    resolve(config);
  });
});

pediaApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    // if not  network error or
    // Request aborted
    //Sentry.captureException(error);
    throw error;
  }
);

class ApiController {
  static async migrate() {
    const response = await astroSafeApi.get(`/schools/migrate`);
    return response.data;
  }

  static async setIsJoined(studentId) {
    const response = await astroSafeApi.put(`/students/${studentId}`, {
      isJoined: true,
    });
    return response.data;
  }

  static async getSchoolByDeviceId(deviceId) {
    const response = await astroSafeApi.get(`/schools/byDeviceId/${deviceId}`);
    return response.data;
  }

  static async joinClassroom(studentId, joinClassCode) {
    const response = await astroSafeApi.post("/classrooms/join", {
      studentId,
      joinClassCode,
    });
    return response.data;
  }

  static async joinSession(schoolId, studentId, joinCode) {
    const response = await astroSafeApi.post(
      `/schools/${schoolId}/joinSession/${joinCode}`,
      {
        studentId,
      }
    );
    return response.data;
  }

  static async addDeviceToSchool(schoolId, name) {
    const response = await astroSafeApi.post(`/schools/${schoolId}/addDevice`, {
      name,
      type: "chrome",
    });
    return response.data;
  }

  static async addToDeviceHistory(deviceId, studentId) {
    const response = await astroSafeApi.post(
      `/schools/devices/${deviceId}/addToHistory`,
      {
        studentId,
      }
    );
    return response.data;
  }

  static async capDeviceHistory(deviceId) {
    const response = await astroSafeApi.post(`/schools/devices/${deviceId}/capHistory`);
    return response.data;
  }

  static async verifyJoinCode(joinCode) {
    const response = await astroSafeApi.post("/schools/verifySchool", {
      joinCode,
    });
    return response.data;
  }

  static async getGuestLinks() {
    const response = await astroSafeApi.get(`/schools/guest/guestlinks`);
    return response.data;
  }

  static async getGuestChannels() {
    const response = await astroSafeApi.get(`/schools/guest/guestchannels`);
    return response.data;
  }

  static async getPlatforms(deviceId) {
    const response = await astroSafeApi.get(`/schools/devices/${deviceId}/platforms`);
    return response.data;
  }

  static async getGuestPlatforms() {
    const response = await astroSafeApi.get(`/schools/guest/guestplatforms`);
    return response.data;
  }

  static async getSchoolLessons(schoolId) {
    const response = await astroSafeApi.get("/schools/" + schoolId + "/lessons");
    return response.data;
  }

  static async getSchoolStudents(schoolId) {
    const response = await astroSafeApi.get("/schools/" + schoolId + "/students");
    return response.data;
  }

  static async getSchoolTeachers(schoolId) {
    const response = await astroSafeApi.get("/schools/" + schoolId + "/teachers");
    return response.data;
  }

  static async getTags() {
    const response = await astroSafeApi.get("/tags");
    return response.data;
  }

  static async checkStudentExists(email) {
    const response = await astroSafeApi.post("/students/checkStudentExists", { email });
    return response.data;
  }

  static async search(value) {
    const response = await astroSafeApi.post("/classrooms/search", { searchVal: value });
    return response.data;
  }

  static async getChannels(deviceId) {
    const response = await astroSafeApi.get(`/schools/devices/${deviceId}/channels`);
    return response.data;
  }

  static async getLinks(deviceId) {
    const response = await astroSafeApi.get(`/schools/devices/${deviceId}/links`);
    return response.data;
  }

  static async getStackLinks(stackId) {
    const response = await astroSafeApi.get(`/stacks/${stackId}/links`);
    return response.data;
  }

  static async getStacks(deviceId) {
    const response = await astroSafeApi.get(`/schools/devices/${deviceId}/stacks`);
    return response.data;
  }

  static async getHistory(deviceId) {
    const response = await astroSafeApi.get(`/schools/devices/${deviceId}/history`);
    return response;
  }

  static async getContentAgeMode(deviceId) {
    const response = await astroSafeApi.get(`/schools/devices/${deviceId}/contentAgeMode`);
    return response.data;
  }

  static async getSearchResults(deviceId, searchValue) {
    const response = await astroSafeApi.post(`/schools/searchResults`, {
      deviceId,
      searchValue,
    });
    return response.data;
  }

  static async queryPedia(value) {
    const response = await pediaApi.get(`/pedia/query/single`, {
      params: { value },
    });
    return response.data;
  }
}

export default ApiController;