import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ApiController from "../../apiController";
import AstroContentColumns from "../../components/AstroContentColumns";
import { PALETTE } from "../../palette";
import {
  IDEAL_COLUMN_WIDTH,
  ILink,
  MAX_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
} from "./MainPage";
import { useLocation, useSearchParams } from "react-router-dom";
import { ISearchResult } from "./components/SearchResultCard";
import SearchPageLandingView from "./SearchPageLandingView";
import { useLocalStorage } from "usehooks-ts";
import PageLayout from "../../components/PageLayout";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import CaptchaDialog from "./CaptchaDialog";

export type ContentAgeMode = "trailblazer" | "explorer" | "adventurer";

export interface IPediaMainCard {
  id: string;
  imageUrl: string;
  facts: IPediaFact[];
}

interface IPediaTextBlock {
  id: string;
  title: string;
  content: string[];
}

interface IPediaImage {
  id: string;
  url: string;
  caption?: string;
}

interface IPediaFact {
  title: string;
  content: string;
}

interface IPediaAnswer {
  id: string;
  value: string;
}

interface IPediaQuestion {
  question: string;
  answers: IPediaAnswer[];
  correctAnswer: string;
}

interface IPediaPage {
  title: string;
  parentIds: string[];
  mainCard: IPediaMainCard;
  textBlocks: { age: number; blocks: IPediaTextBlock[] }[];
  images: IPediaImage[];
  funFact: string;
  questions: IPediaQuestion[];
}

interface IPediaCollectionPage {
  title: string;
}

export default function SearchPage(props: { showJoiningBanner: boolean }) {
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("q");

  const [links, setLinks] = useState<ILink[]>([]);
  const [externalSearchResults, setExternalSearchResults] = useState<
    ISearchResult[]
  >([]);
  const [pediaPage, setPediaPage] = useState<IPediaPage | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );

  const [showBlockedQueryView, setShowBlockedQueryView] =
    useState<boolean>(false);

  const [contentAgeMode, setContentAgeMode] = useLocalStorage<ContentAgeMode>(
    "contentAgeMode",
    "explorer"
  );

  useEffect(() => {
    setLoading(true);
    ApiController.getSearchResults(deviceId, searchValue)
      .then((results) => {
        if (results.isBlocked) {
          setShowBlockedQueryView(true);
        } else {
          setShowBlockedQueryView(false);
          //@ts-ignore
          setLinks(results.internal ?? []);
          //setExternalSearchResults(results.external);
        }
      })
      .finally(() => setLoading(false));
    ApiController.queryPedia(searchValue)
      .then((page) => setPediaPage(page))
      .finally(() => setLoading(false));
  }, [searchValue]);

  const extractSearchResults = () => {
    const searchResults = [...document.querySelectorAll(".gsc-webResult")];
    searchResults &&
      setExternalSearchResults(
        _(searchResults)
          .map((sr: any) => sr.querySelector("a"))
          .compact()
          .map((srElement: any) => ({
            id: uuidv4(),
            title: srElement.innerText ?? "",
            url: srElement.href ?? "",
            text: srElement.innerText ?? "",
          }))
          .unionBy((item) => item.title)
          .filter((item) => item.url && !item.url.includes("google."))
          .value()
      );
  };

  const location = useLocation();

  useEffect(() => {
    if (contentAgeMode === "trailblazer") return;

    const oldScripts = document.querySelectorAll("script");
    [...oldScripts].forEach((s) => {
      if (s.src.includes("cse")) {
        s?.parentNode?.removeChild(s);
      }
    });

    const oldLinks = document.querySelectorAll("link");
    [...oldLinks].forEach((s) => {
      if (s.href.includes("cse")) {
        s?.parentNode?.removeChild(s);
      }
    });

    const script = document.createElement("script");
    script.setAttribute("id", "google-script");
    document.head.append(script);
    script.src = "https://cse.google.com/cse.js?cx=964a8383a3a5d41cf";
    const delayedFunction = setTimeout(() => {
      extractSearchResults();
    }, 2000);

    return () => {
      clearTimeout(delayedFunction);
    };
  }, [location.search, contentAgeMode]);

  return (
    <PageLayout
      selected="search"
      backgroundColor={searchValue ? undefined : PALETTE.primary.navy}
    >
      <Stack
        flex={1}
        overflow="scroll"
        px={searchValue && !showBlockedQueryView ? "16px" : undefined}
      >
        {searchValue && !showBlockedQueryView ? (
          <AstroContentColumns
            title={`"${searchValue}"`}
            links={links}
            stacks={[]}
            platforms={[]}
            searchResults={externalSearchResults}
            pediaCard={pediaPage?.mainCard}
            pediaCardTitle={pediaPage?.title}
            minColumnWidth={MIN_COLUMN_WIDTH}
            maxColumnWidth={MAX_COLUMN_WIDTH}
            idealColumnWidth={IDEAL_COLUMN_WIDTH}
            emptyStateText={
              loading ? undefined : "Nothing found with this search term."
            }
          />
        ) : (
          <SearchPageLandingView blockedTerm={showBlockedQueryView} />
        )}
      </Stack>
      <CaptchaDialog />
    </PageLayout>
  );
}
