import { Stack } from "@mui/system";
import Typography from "../../../components/Typography";
import { PALETTE } from "../../../palette";

const FORCE_ELLIPSIFICATION_THRESHOLD = 40;

export interface ISearchResult {
  id: string;
  title: string;
  text: string;
  url: string;
  createdAt?: string;
}

export default function SearchResultCard(props: ISearchResult) {
  return (
    <Stack
      flex={1}
      width="100%"
      bgcolor="white"
      borderRadius="14px"
      spacing="15px"
      p="20px"
      boxSizing="border-box"
      justifyContent="space-between"
    >
      <Typography
        variant="large"
        bold
        color={PALETTE.secondary.purple[2]}
        maxLines={2}
        sx={{
          background: "linear-gradient(-28deg, #F279C5, #1D62F6)",
          "-webkit-text-fill-color": "transparent",
          backgroundClip: "text",
          "-webkit-background-clip": "text",
        }}
      >
        {props.title +
          (props.title.length > FORCE_ELLIPSIFICATION_THRESHOLD ? " ." : "")}
      </Typography>
      <Typography maxLines={3}>{props.text.slice(0, 200)}</Typography>
    </Stack>
  );
}
