import { useState } from "react";
import { Stack, keyframes } from "@mui/system";
import { ReactComponent as LinkIcon } from "../../../images/icons/LinkIcon.svg";
import { ReactComponent as Star } from "../../../images/Star.svg";
import { DEFAULT_CORNER_RADIUS } from "../../../components/UrsorPopover";
import { PALETTE } from "../../../palette";
import Typography from "../../../components/Typography";
import { IStack } from "../MainPage";

const STACK_CARD_HEIGHT = "258px";

export const spin = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`;

const StackCard = (props: { stack: IStack }) => {
  const [hovering, setHovering] = useState<boolean>(false);
  return (
    <Stack
      width="100%"
      borderRadius={DEFAULT_CORNER_RADIUS}
      border={`4px solid rgb(255,255,255)`}
      sx={{
        transition: "0.2s",
      }}
      bgcolor="rgb(255,255,255)"
      boxSizing="border-box"
      overflow="hidden"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      position="relative"
    >
      <Stack
        height="100%"
        sx={{
          cursor: "pointer",
          transition: "0.2s",
          "&:hover": { opacity: 0.6 },
        }}
      >
        <Stack
          height="156px"
          minHeight="156px"
          width="100%"
          direction="row"
          spacing="4px"
        >
          <Stack
            flex={1}
            bgcolor={props.stack.backgroundColors[0]}
            position="relative"
            justifyContent="center"
            alignItems="center"
            sx={{
              opacity: 0.74,
              svg: {
                transform: "rotate(26deg)",
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: props.stack?.imageUrls[0]
                ? `url(${props.stack?.imageUrls[0]})`
                : undefined,
            }}
          >
            {!props.stack?.imageUrls[0] ? (
              <Stack
                sx={{
                  animation: `${spin} 9s linear`,
                  animationIterationCount: "infinite",
                }}
              >
                <Star height="52px" width="52px" />
              </Stack>
            ) : null}
          </Stack>
          <Stack spacing="4px" width="30%">
            <Stack
              flex={1}
              bgcolor={props.stack.backgroundColors[1]}
              position="relative"
              justifyContent="center"
              alignItems="center"
              sx={{
                opacity: 0.74,
                svg: {
                  transform: "rotate(39deg)",
                },
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: props.stack?.imageUrls[1]
                  ? `url(${props.stack?.imageUrls[1]})`
                  : undefined,
              }}
            >
              {!props.stack?.imageUrls[1] ? (
                <Stack
                  sx={{
                    animation: `${spin} 12s linear`,
                    animationDirection: "reverse",
                    animationIterationCount: "infinite",
                  }}
                >
                  <Star height="20px" width="20px" />
                </Stack>
              ) : null}
            </Stack>
            <Stack
              flex={1}
              bgcolor={props.stack.backgroundColors[2]}
              position="relative"
              justifyContent="center"
              alignItems="center"
              sx={{
                opacity: 0.74,
                svg: {
                  transform: "rotate(50deg)",
                },
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: props.stack?.imageUrls[2]
                  ? `url(${props.stack?.imageUrls[2]})`
                  : undefined,
              }}
            >
              {!props.stack?.imageUrls[2] ? (
                <Stack
                  sx={{
                    animation: `${spin} 4s linear`,
                    animationIterationCount: "infinite",
                  }}
                >
                  <Star height="20px" width="20px" />
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        <Stack px="4px" pt="8px" pb="4px" alignItems="space-between" flex={1}>
          <Stack direction="row" flex={1} overflow="scroll">
            <Typography bold variant="medium">
              {props.stack.title}
            </Typography>
            <Stack minWidth="25px" />
          </Stack>
          <Stack width="100%" alignItems="flex-end">
            <Stack
              direction="row"
              spacing="5px"
              alignItems="center"
              sx={{
                svg: {
                  path: {
                    fill: PALETTE.secondary.grey[3],
                  },
                },
              }}
            >
              <Typography variant="small" color={PALETTE.secondary.grey[3]}>
                {`${props.stack?.nLinks ?? 0}`}
              </Typography>
              <LinkIcon width="13px" height="13px" />
              {/* <Typography variant="small" color={PALETTE.secondary.grey[3]}>
                    {`Link${
                      !props.stack?.nLinks || props.stack?.nLinks > 1 ? "s" : ""
                    }`}
                  </Typography> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StackCard;
