import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ApiController from "../../apiController";
import _ from "lodash";
import Typography from "../../components/Typography";
import { PALETTE } from "../../palette";
import Navbar from "../../components/Navbar";
import moment from "moment";
import { useLocalStorage } from "usehooks-ts";
import HistoryPageContent from "./HistoryPageContent";
import { HIDE_SCROLLBAR } from "./SearchPageLandingView";
import { useSearchParams } from "react-router-dom";

const PADDING = "20px";
export const OVERALL_TOP_PADDING = "78px";

export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm A";

export const getIsPast = (date: string) => moment(date) < moment();

export const getIsToday = (date: string) =>
  moment(date) < moment().endOf("day") &&
  moment(date) >= moment().startOf("day");

export const getIsTomorrow = (date: string) =>
  moment(date) < moment().add(1, "days").endOf("day") &&
  moment(date) >= moment().add(1, "days").startOf("day");

export const getIsYesterday = (date: string) =>
  moment(date) < moment().subtract(1, "days").endOf("day") &&
  moment(date) >= moment().subtract(1, "days").startOf("day");

export const getFormattedDate = (date: string) =>
  moment(date).format(DATE_FORMAT);

export const getFormattedTime = (date: string) =>
  moment(date).format(TIME_FORMAT);

export interface IBrowsingState {
  deviceId: string;
  studentId?: string;
  url: string;
  title: string;
  favIconUrl?: string;
  duration: number;
  timestamp: number;
  createdAt: string;
  updatedAt: string;
  id: string;
}

export interface IDeviceHistoryDatedItem {
  date: string;
  items: IBrowsingState[];
}

export default function HistoryPage(props: { showJoiningBanner: boolean }) {
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );
  const [searchParams] = useSearchParams();
  const [history, setHistory] = useState<IDeviceHistoryDatedItem[]>([]);
  const loadHistory = () =>
    ApiController.getHistory(deviceId).then((response) => {
      const hist: IBrowsingState[] = response.data.filter(
        (item: IBrowsingState) => item.url.startsWith("https:")
      );
      const dateGroupedHistory = _.groupBy(hist, (item) =>
        moment(item?.createdAt).format("YYYY-MM-DD")
      );
      const datedHistoryArray = Object.entries(dateGroupedHistory).map(
        ([date, items]) => ({
          date,
          items,
        })
      );
      setHistory(datedHistoryArray as IDeviceHistoryDatedItem[]);
    });

  useEffect(() => {
    loadHistory();
  }, []);

  return (
    <Stack
      width="100vw"
      height="100vh"
      overflow="hidden"
      bgcolor={PALETTE.secondary.grey[1]}
      position="relative"
    >
      <Stack
        pt={PADDING}
        pb="64px"
        px="32px"
        flex={1}
        sx={HIDE_SCROLLBAR}
        spacing={PADDING}
        overflow="scroll"
      >
        <Stack spacing="15px" pt={OVERALL_TOP_PADDING}>
          <Typography variant="h5">History</Typography>
        </Stack>
        <HistoryPageContent history={history} />
      </Stack>
      {searchParams.get("isapp") !== "true" ? (
        <Stack position="absolute" top={0} width="100%">
          <Navbar selected="history" />
        </Stack>
      ) : null}
    </Stack>
  );
}
