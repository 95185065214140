import { useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";

const useColumnWidth = (
  minWidth: number,
  maxWidth: number,
  idealWidth: number
) => {
  const [nColumns, setNColumns] = useState<number>(1);

  const { width } = useWindowSize();

  const [columnsContainerRef, setColumnsContainerRef] =
    useState<HTMLElement | null>(null);
  useEffect(() => {
    const possibleNColumns = Math.floor(
      (columnsContainerRef?.getBoundingClientRect().width ?? 0) / idealWidth
    );

    const possibleColumnWidth =
      (columnsContainerRef?.getBoundingClientRect().width ?? 0) /
      possibleNColumns;
    if (possibleColumnWidth > minWidth) {
      if (possibleColumnWidth < maxWidth) {
        setNColumns(possibleNColumns);
      } else {
        setNColumns(possibleNColumns + 1);
      }
    } else {
      setNColumns(Math.max(1, possibleNColumns - 1));
    }
  }, [columnsContainerRef, width]);

  return { nColumns, setColumnsContainerRef };
};

export default useColumnWidth;
