/*global chrome*/
import { Stack } from "@mui/system";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./pages/main/MainPage";
import PlatformsPage from "./pages/main/PlatformsPage";
import SearchPage, { ContentAgeMode } from "./pages/main/SearchPage";
import HistoryPage from "./pages/main/HistoryPage";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useListeners } from "./useListeners";
import { PALETTE } from "./palette";
import ApiController from "./apiController";
import mixpanel from "mixpanel-browser";

export const EXTENSION_ID = "bgmnmkccofmfpgfbamfklejbjboddbep";

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

function App() {
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );

  useEffect(() => {
    deviceId && mixpanel.identify(deviceId);
  }, [deviceId]);

  const [contentAgeMode, setContentAgeMode] = useLocalStorage<ContentAgeMode>(
    "contentAgeMode",
    "explorer"
  );
  useEffect(() => {
    if (deviceId) {
      ApiController.getContentAgeMode(deviceId).then((mode) =>
        setContentAgeMode(mode)
      );
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceId) {
      if (typeof chrome !== "undefined") {
        chrome?.runtime?.sendMessage(EXTENSION_ID, {
          deviceId,
        });
      }
    }
  }, [deviceId]);

  const [schoolId, setSchoolId] = useLocalStorage<string | undefined>(
    "schoolId",
    undefined
  );

  const receiveExtensionMessage = (event: any) => {
    if (event.data.kickOutOfSchool) {
      setDeviceId(undefined);
      if (typeof chrome !== "undefined") {
        setSchoolId(undefined);
        chrome?.runtime?.sendMessage(EXTENSION_ID, {
          kickedOut: true,
        });
      }
    }
  };

  useListeners();

  useEffect(() => {
    window.addEventListener("message", receiveExtensionMessage, false);
    return () => {
      window.removeEventListener("message", receiveExtensionMessage);
    };
  }, [window]);

  return (
    <>
      <Stack
        width="100vw"
        height="100vh"
        overflow="hidden"
        zIndex={1}
        bgcolor={PALETTE.secondary.grey[1]}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path={"/"}
              element={<MainPage showJoiningBanner={!deviceId} />}
            />
            <Route
              path={"/apps"}
              element={<PlatformsPage showJoiningBanner={!deviceId} />}
            />
            <Route
              path={"/search"}
              element={<SearchPage showJoiningBanner={!deviceId} />}
            />
            <Route
              path={"/history"}
              element={<HistoryPage showJoiningBanner={!deviceId} />}
            />
          </Routes>
        </BrowserRouter>
      </Stack>
    </>
  );
}

export default App;
