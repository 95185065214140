import { Stack, alpha } from "@mui/system";
import { PALETTE } from "../palette";
import { ReactComponent as HomeIcon } from "../images/icons/HomeIcon.svg";
import { ReactComponent as EmptyCheckboxIcon } from "../images/icons/EmptyCheckboxIcon.svg";
import { ReactComponent as SearchIcon } from "../images/icons/SearchIcon.svg";
import { ReactComponent as ClockIcon } from "../images/icons/ClockIcon.svg";
import { useNavigate } from "react-router-dom";
import UrsorButton from "./UrsorButton";
import { useLocalStorage } from "usehooks-ts";
import NavbarSearchBar from "./NavbarSearchBar";
import BuddyByte from "./BuddyByte";
import { GRID_SPACING } from "./AstroContentColumns";
import mixpanel from "mixpanel-browser";

const BUTTON_SIZE = 52;
const BUTTON_SPACING = "12px";
const ICON_SIZE = "28px";
const SHADOW = "0px 0px 26px rgba(0,0,0,0.06)";
export const MAGICAL_GRADIENT =
  "linear-gradient(150deg, #FD9B41, #F279C5, #1D62F6, #0AE799)";

const PADDING = 10;

const SELECTION_BORDER_THICKNESS = 3;

export function NavigationButton(props: {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  color: string;
  callback?: () => void;
  selected?: boolean;
}) {
  return (
    <Stack
      width={`${BUTTON_SIZE + 2 * SELECTION_BORDER_THICKNESS}px`}
      minWidth={`${BUTTON_SIZE + 2 * SELECTION_BORDER_THICKNESS}px`}
      height={`${BUTTON_SIZE + 2 * SELECTION_BORDER_THICKNESS}px`}
      sx={{
        background: props.selected ? MAGICAL_GRADIENT : undefined,
      }}
      borderRadius="100%"
      justifyContent="center"
      alignItems="center"
      onClick={() => mixpanel.track("boo")}
    >
      <Stack
        height={BUTTON_SIZE}
        width={BUTTON_SIZE}
        borderRadius="100%"
        bgcolor={PALETTE.secondary.grey[1]}
        justifyContent="center"
        alignItems="center"
        sx={{
          ...(!props.selected
            ? {
                "&:hover": { opacity: 0.7, transform: "scale(1.1)" },
                transition: "0.2s",
                cursor: "pointer",
              }
            : {}),
          svg: {
            path: {
              fill: props.color,
            },
          },
        }}
        onClick={props.callback}
      >
        <props.icon height={ICON_SIZE} width={ICON_SIZE} />
      </Stack>
    </Stack>
  );
}

export type NavbarButton = "home" | "apps" | "search" | "history";

export interface INavbarProps {
  selected?: NavbarButton;
  noByte?: boolean;
  noSearch?: boolean;
}

export default function Navbar(props: INavbarProps) {
  const navigate = useNavigate();
  return (
    <>
      <Stack
        width="100%"
        py={`${PADDING}px`}
        px={`calc(2*${GRID_SPACING})`}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="white"
        boxSizing="border-box"
        position="relative"
        boxShadow={SHADOW}
      >
        <Stack direction="row" spacing={BUTTON_SPACING}>
          <NavigationButton
            icon={HomeIcon}
            color={alpha(
              PALETTE.secondary.blue[4],
              props.selected === "home" ? 1 : 0.65
            )}
            callback={() => navigate("/")}
            selected={props.selected === "home"}
          />
          <NavigationButton
            icon={EmptyCheckboxIcon}
            color={alpha(
              PALETTE.secondary.blue[4],
              props.selected === "apps" ? 1 : 0.65
            )}
            callback={() => navigate("/apps")}
            selected={props.selected === "apps"}
          />
          <NavigationButton
            icon={ClockIcon}
            color={alpha(
              PALETTE.secondary.blue[4],
              props.selected === "history" ? 1 : 0.65
            )}
            callback={() => navigate("/history")}
            selected={props.selected === "history"}
          />
          {/* {dataCtx.lessons.length > 0 ? (
              <NavigationButton
                icon={ClassroomsIcon}
                color={PALETTE.secondary.green[4]}
                callback={() => navigate("/lessons")}
                selected={props.selected === "lessons"}
              />
            ) : null} */}
        </Stack>
        {/* <NavigationButton
        icon={SignoutIcon}
        color={PALETTE.secondary.grey[4]}
        callback={() => {
          userCtx.lockOut();
          userCtx.schoolStudents && userCtx.clear();
          navigate("/");
        }}
      /> */}
        {!props.noSearch ? <NavbarSearchBar /> : null}
        <Stack height="100%" alignItems="center">
          {!props.noByte ? <BuddyByte /> : null}
        </Stack>
      </Stack>
    </>
  );
}
