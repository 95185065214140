import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { Stack } from "@mui/system";
import Typography from "../../components/Typography";
import { PALETTE } from "../../palette";
import { ReactComponent as X } from "../../images/icons/X.svg";

const WIDTH = "550px";
const MIN_HEIGHT = "470px";

export interface ICaptchaDialogProps {
  //open: boolean;
  //closeCallback: () => void;
}

export default function CaptchaDialog(props: ICaptchaDialogProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [openedAlready, setOpenedAlready] = useState<boolean>(false);

  // useEffect(() => {
  //   const delayedFunction = setTimeout(() => {
  //     setOpen(!!document.querySelector("[title=reCAPTCHA]"));
  //   }, 2000);

  //   return () => {
  //     clearTimeout(delayedFunction);
  //   };
  // }, []);

  useEffect(() => {
    const interval = setInterval(
      () => setOpen(!!document.querySelector("[title=reCAPTCHA]")),
      3000
    );
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    open && setOpenedAlready(true);
    if (!open && openedAlready) {
      window.location.href = window.location.href + " ";
    }
  }, [open]);

  return (
    <Dialog
      transitionDuration={1000}
      // open={props.open}
      // onClose={props.closeCallback}
      open={true}
      onClose={() => setOpen(false)}
      PaperProps={{
        elevation: 0, // removes shadow
        style: {
          width: WIDTH,
          maxWidth: WIDTH,
          borderRadius: "25px",
          opacity: open ? 1 : 0,
          transition: "0.2s",
        },
      }}
      sx={{
        py: "10px",
        backdropFilter: open ? "blur(4px)" : undefined,
        visibility: !open ? "hidden" : undefined,
        pointerEvents: !open ? "none" : undefined,
        a: {
          display: "none",
        },
        form: {
          display: "none",
        },
        ".gs-captcha-msg": {
          display: "none",
        },
        ".gs-webResult": {
          marginTop: "0 !important",
        },
        ".gsc-control-cse": {
          width: "fit-content",
          padding: 0,
        },
        ".gs-captcha-wrapper": {
          margin: "0 !important",
        },
        ".gsc-positioningWrapper": {
          display: "none",
        },
        ".gsc-above-wrapper-area": {
          display: "none",
        },
        ".gsc-wrapper": {
          display: "none",
        },
      }}
    >
      <Stack
        position="absolute"
        top="20px"
        right="20px"
        onClick={() => setOpen(false)}
        sx={{
          cursor: "pointer",
          "&:hover": { opacity: 0.6 },
          transition: "0.2s",
        }}
      >
        <X height="27px" />
      </Stack>
      <Stack flex={1} spacing="16px" alignItems="center" p="40px">
        <Typography variant="h4" color={PALETTE.secondary.purple[2]}>
          Are you a Robot?
        </Typography>
        <Stack spacing="3px" alignItems="center">
          <Typography>
            Sometimes when you search for many things quickly,
          </Typography>
          <Typography>
            the Internet wants to find out whether you are
          </Typography>
          <Typography>a Human or a Robot.</Typography>
        </Stack>
        <Typography variant="h0">🤖</Typography>
        <Stack alignItems="center" spacing="5px">
          <Typography>
            So let's click the square to say that we are Humans.
          </Typography>
          <div className="gcse-search" />
        </Stack>
      </Stack>
    </Dialog>
  );
}
