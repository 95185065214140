import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

export const useListeners = () => {
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );
  const [schoolId, setSchoolId] = useLocalStorage<string | undefined>(
    "schoolId",
    undefined
  );
  // this creates the listeners for the iOS version
  useEffect(() => {
    const disconnectSchooliOS = (event: any) => {
      if (
        event.data &&
        event.data.source &&
        event.data.source === "iOSAstrosafe"
      ) {
        setSchoolId(undefined);
        setDeviceId(undefined);
      }
    };

    const GetDetailsFromiOS = (event: any) => {
      if (
        event.data &&
        event.data.source &&
        event.data.source === "iOSAstrosafe"
      ) {
        if (
          event.data.details &&
          event.data.details.schoolId &&
          event.data.details.deviceId
        ) {
          if (
            (!deviceId && !schoolId) ||
            (deviceId !== event.data.details.deviceId &&
              schoolId !== event.data.details.schoolId)
          ) {
            setSchoolId(event.data.details.schoolId);
            setDeviceId(event.data.details.deviceId);
          }
        } else if (
          deviceId &&
          schoolId &&
          !event.data.details.deviceId &&
          !event.data.details.schoolId
        ) {
          // If there is no deviceId or schoolId from the browser side, check if these exist locally and return them
          if (
            (window as any).webkit &&
            (window as any).webkit.messageHandlers &&
            (window as any).webkit.messageHandlers.websiteToiOSDetails
          ) {
            const data = {
              schoolId,
              deviceId,
            };
            const jsonData = JSON.stringify(data);
            (
              window as any
            ).webkit.messageHandlers.websiteToiOSDetails.postMessage(jsonData);
          }
        }
      }
    };

    window.addEventListener("iOSAstrosafeDisconnect", disconnectSchooliOS);
    window.addEventListener("iOSAstrosafeGetDetails", GetDetailsFromiOS);
    // Clean up the event listener when the component unmounts
    return () => {
      window.addEventListener("iOSAstrosafeDisconnect", disconnectSchooliOS);
      window.removeEventListener("iOSAstrosafeGetDetails", GetDetailsFromiOS);
    };
  }, []);
};
