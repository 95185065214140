import { Stack } from "@mui/system";
import { ReactComponent as Cogs } from "../images/cogs.svg";
import Typography from "./Typography";

export default function NoConnection(props: { children: React.ReactNode }) {
  return navigator.onLine ? (
    <>{props.children}</>
  ) : (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      position="relative"
      spacing="20px"
    >
      <Cogs />
      <Typography variant="h2" sx={{ fontFamily: "sans-serif" }}>
        No connection
      </Typography>
      <Typography variant="medium" bold sx={{ fontFamily: "sans-serif" }}>
        Reconnect to WIFI and try again.
      </Typography>
    </Stack>
  );
}
