import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import ApiController from "../../apiController";
import _ from "lodash";
import Typography from "../../components/Typography";
import AstroContentColumns, {
  GRID_SPACING,
} from "../../components/AstroContentColumns";
import { PALETTE } from "../../palette";
import { useLocalStorage } from "usehooks-ts";
import UrsorFadeIn from "../../components/UrsorFadeIn";
import { HIDE_SCROLLBAR } from "./SearchPageLandingView";
import { useSearchParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";

export const MIN_COLUMN_WIDTH = 245;
export const MAX_COLUMN_WIDTH = 402;
export const IDEAL_COLUMN_WIDTH = 271;

export interface IChannel {
  id: string;
  creatorId: string;
  schoolId: string;
  title: string;
  nLinks: number;
  nStacks: number;
  color: string;
}

export interface ILink {
  id: string;
  creatorId: string;
  schoolId: string;
  channelId: string;
  stackId?: string;
  lessonId: string;
  title: string;
  url: string;
  accessibleUrl: string;
  imageUrl: string;
  color: string;
  updatedAt: string;
  createdAt: string;
}

export interface IStack {
  id: string;
  creatorId: string;
  schoolId: string;
  channelId: string;
  title: string;
  description?: string;
  imageUrls: string[];
  backgroundColors: string[];
  createdAt: string;
  nLinks: number;
}

const ChannelsColumn = (props: {
  channels: IChannel[];
  selected?: string;
  selectionCallback: (id: string) => void;
}) => (
  <Stack
    width="300px"
    height="100%"
    px={`calc(2*${GRID_SPACING})`}
    boxSizing="border-box"
    bgcolor="rgb(255,255,255)"
  >
    <Stack
      flex={1}
      overflow="scroll"
      sx={HIDE_SCROLLBAR}
      boxSizing="border-box"
      pt={`calc(2*${GRID_SPACING} - 3px)`}
      pb="80px"
    >
      {_.reverse(props.channels.slice()).map((c, i) => (
        <UrsorFadeIn key={c.id} duration={800} delay={i * 40}>
          <Stack
            key={c.id}
            height="44px"
            minHeight="44px"
            width="100%"
            borderRadius="8px"
            boxSizing="border-box"
            px="10px"
            bgcolor={
              props.selected === c.id ? PALETTE.secondary.purple[2] : undefined
            }
            sx={{
              "&:hover": { opacity: 0.5 },
              transition: "0.2s",
              cursor: "pointer",
            }}
            justifyContent="center"
            onClick={() => props.selectionCallback(c.id)}
          >
            <Stack direction="row" alignItems="center" spacing="8px">
              <Stack
                height="16px"
                width="16px"
                bgcolor={c.color || PALETTE.secondary.green[2]}
                borderRadius="100%"
              />
              <Typography
                bold
                noWrap
                color={
                  props.selected === c.id
                    ? PALETTE.font.light
                    : PALETTE.font.dark
                }
              >
                {c.title}
              </Typography>
            </Stack>
          </Stack>
        </UrsorFadeIn>
      ))}
    </Stack>
  </Stack>
);

export default function MainPage(props: { showJoiningBanner: boolean }) {
  const [deviceId, setDeviceId] = useLocalStorage<string | undefined>(
    "deviceId",
    undefined
  );
  const [searchParams] = useSearchParams();

  const [channels, setChannels] = useState<IChannel[]>([]);
  const [links, setLinks] = useState<ILink[]>([]);
  const [stacks, setStacks] = useState<IStack[]>([]);
  useEffect(() => {
    (deviceId
      ? ApiController.getLinks(deviceId)
      : ApiController.getGuestLinks()
    ).then((links) => setLinks(_.reverse(links.slice())));
    deviceId
      ? ApiController.getStacks(deviceId).then((stacks) => setStacks(stacks))
      : setStacks([]);
    (deviceId
      ? ApiController.getChannels(deviceId)
      : ApiController.getGuestChannels()
    ).then((channels) => setChannels(channels));
  }, [deviceId]);

  const [selectedChannelId, setSelectedChannelId] = useState<
    string | undefined
  >(undefined);
  useEffect(() => {
    !channels.find((c) => c.id === selectedChannelId) &&
      setSelectedChannelId(channels[channels.length - 1]?.id);
  }, [channels]);

  const [filteredLinks, setFilteredLinks] = useState<ILink[]>([]);
  useEffect(
    () =>
      setFilteredLinks(
        links?.filter((l) => !l.stackId && l.channelId === selectedChannelId)
      ),
    [links, selectedChannelId]
  );
  const [filteredStacks, setFilteredStacks] = useState<IStack[]>([]);
  useEffect(
    () =>
      setFilteredStacks(
        stacks?.filter((l) => l.channelId === selectedChannelId)
      ),
    [stacks, selectedChannelId]
  );

  return (
    <PageLayout selected="home">
      <Stack width="100vw" height="100vh" overflow="hidden">
        <Stack flex={1} direction="row" overflow="hidden">
          <ChannelsColumn
            channels={channels}
            selected={selectedChannelId}
            selectionCallback={(id) => setSelectedChannelId(id)}
          />
          <Stack
            flex={1}
            bgcolor={PALETTE.secondary.grey[1]}
            overflow="scroll"
            pr={GRID_SPACING}
          >
            <AstroContentColumns
              title={
                channels.find((c) => c.id === selectedChannelId)?.title ?? ""
              }
              links={filteredLinks}
              stacks={filteredStacks}
              platforms={[]}
              searchResults={[]}
              minColumnWidth={MIN_COLUMN_WIDTH}
              maxColumnWidth={MAX_COLUMN_WIDTH}
              idealColumnWidth={IDEAL_COLUMN_WIDTH}
              shareSelectedStackIdWithExtension
              emptyStateText="No Links yet."
            />
          </Stack>
        </Stack>
      </Stack>
    </PageLayout>
  );
}
